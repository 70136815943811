.anw-seo-link-column {
  width: 310px;
}

.anw-scroll-content-large {
  max-height: 290px;
}

.anw-scroll-content-small {
  max-height: 115px;
}

.dropdown {
  .collapse-less {
    display: none;
  }
  &.show {
    .collapse-more {
      display: none;
    }
    .collapse-less {
      display: inline-block;
    }
  }
}
